"use client";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

import { CFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth/hooks";

export const NotAuthedRoute: CFC = ({
  children,
}) => {
  const { isSignedIn, isIdle } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    if (!isIdle && isSignedIn) {
      router.replace(routes.TOP);
    }
  }, [isSignedIn, isIdle]);

  if (isIdle || isSignedIn) {
    return <></>;
  }

  return <>{children}</>;
};
