import { createContext, useContext } from "react";

import { UserMe } from "shared/models";
import { CFC } from "shared/types";

import { useAuth } from "./useAuth";

type AuthContextType = ReturnType<typeof useAuth>;

const AuthContext = createContext<AuthContextType>({
  user: new UserMe(),
  accessToken: "",
  getAccessToken: () => async () => "",
  signIn: () => void(0),
  signOut: () => void(0),
  waitToken: () => Promise.resolve(""),
  refetch: () => void(0),
  isSignedIn: false,
  isLoading: false,
  isIdle: true,
  isAccidentallySignedOut: false,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: CFC = ({ children }) => {
  const value = useAuth();

  return (
    <AuthContext.Provider value={value}>
      {value.isIdle ? null : children}
    </AuthContext.Provider>
  );
};
