import { useQuery } from "@tanstack/react-query";
import jsCookie from "js-cookie";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

import { UserMe, Token, UserMeType, TokenType, UserMeTypeType } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { isAPIError } from "shared/types";

const { persistAtom } = recoilPersist({ key: "toiny.web.auth" });

const userAtom = atom<Partial<UserMeType>>({
  key: "user",
  default: new UserMe(),
  effects: [persistAtom],
});

const tokenAtom = atom<TokenType>({
  key: "token",
  default: new Token(),
  effects: [persistAtom],
});

export const useAuth = () => {
  const [userState, setUserState] = useRecoilState(userAtom);
  const [tokenState, setTokenState] = useRecoilState(tokenAtom);
  const user = useMemo(() => new UserMe(userState), [userState]);
  const token = useMemo(() => new Token(tokenState), [tokenState]);
  const accessToken = useMemo(() => token.token, [token.token]);

  const tokenRef = useRef("");
  const api = useWebAPI({ accessToken });
  const [isIdle, setIsIdle] = useState(true);
  const [isAccidentallySignedOut, setIsAccidentallySignedOut] = useState(false);

  const { isLoading, refetch: _refetch } = useQuery(
    ["users/session"],
    () => api.getUserSession(),
    {
      retry: false,
      refetchOnWindowFocus: "always",
      onSuccess: ({ data: { user, guestUser } }) => {
        setIsIdle(false);
        if (user) {
          setUserState(user);
          jsCookie.set("toiny-web-token", token.token, { expires: 30 });
        } else if (guestUser) {
          setUserState(guestUser);
        }
      },
      onError: (e) => {
        setIsIdle(false);
        if (isAPIError(e)) {
          if (e.response.status === 401 && userState.id && userState.type === UserMeTypeType.Normal) {
            signOut();
            setIsAccidentallySignedOut(true);
          }
        }
      },
    },
  );

  useEffect(() => {
    tokenRef.current = tokenState.token;
  }, [tokenState]);

  const signIn = useCallback((user: UserMe, token: Token) => {
    setTokenState(token);
    setUserState(user);
    jsCookie.set("toiny-web-token", token.token, { expires: 30 });
  }, []);

  const signOut = useCallback(() => {
    setTokenState(new Token());
    setUserState(new UserMe());
    jsCookie.remove("toiny-web-token");
  }, []);

  const refetch = useCallback(() => {
    _refetch();
  }, [_refetch]);

  const waitToken = useCallback(() => {
    return new Promise<string>((resolve) => {
      setTimeout(function waiter() {
        if (tokenRef.current) {
          resolve(tokenRef.current);
        } else {
          setTimeout(waiter, 100);
        }
      });
    });
  }, [tokenRef]);

  const getAccessToken = useCallback((allowGuest = false) => async () => {
    if (tokenState.token || !allowGuest) return tokenState.token;

    const { data: { user, token } } = await api.createGuestUser();
    signIn(new UserMe(user), new Token(token));
    return await waitToken();
  }, [tokenState]);

  return {
    user,
    accessToken,
    getAccessToken,
    signIn,
    signOut,
    refetch,
    waitToken,
    isIdle,
    isSignedIn: user.isSignedIn(),
    isLoading,
    isAccidentallySignedOut,
  };
};
