/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CalculateProposalSupport200Response,
  CalculateProposalSupportRequest,
  CalculateTopic201Response,
  CalculateTopicRequest,
  CallbackSupportCheckout200Response,
  CallbackTopicCheckout200Response,
  CloseTopic200Response,
  ConfirmUserEmailRequest,
  ConnectUserProvider200Response,
  ConnectUserProvider400Response,
  ConnectUserProviderRequest,
  CreateBestProposals201Response,
  CreateBestProposalsRequest,
  CreateDirectUpload200Response,
  CreateGuestUser201Response,
  CreateStripeAccount201Response,
  CreateStripeAccountRequest,
  CreateTopicNote201Response,
  CreateTopicNoteRequest,
  CreateUserPaymentMethod201Response,
  CreateUserPaymentMethodRequest,
  DeleteUserPaymentMethod200Response,
  GetBestProposals200Response,
  GetCarouselItems200Response,
  GetCategories200Response,
  GetCategory200Response,
  GetEditorLink200Response,
  GetInquiryReplies200Response,
  GetNotifications200Response,
  GetPayouts200Response,
  GetProposal200Response,
  GetProposalComments200Response,
  GetProposalSupporters200Response,
  GetProposalSupports200Response,
  GetTopic200Response,
  GetTopicDraftProposals200Response,
  GetTopicProposals200Response,
  GetTopicVotes200Response,
  GetTopics200Response,
  GetUser200Response,
  GetUserBalances200Response,
  GetUserBankAccounts200Response,
  GetUserComments200Response,
  GetUserPaymentMethods200Response,
  GetUserProposals200Response,
  GetUserRevenues200Response,
  GetUserSession200Response,
  GetUserSessionOmniauthValue200Response,
  GetUserTopic200Response,
  GetUserTopics200Response,
  PostInquiry201Response,
  PostInquiryReply201Response,
  PostInquiryReplyRequest,
  PostInquiryRequest,
  PostProposalComment201Response,
  PostProposalCommentRequest,
  PostProposalSupport201Response,
  PostProposalSupportRequest,
  PostTopic201Response,
  PostTopicProposal201Response,
  PostTopicProposalRequest,
  PostTopicRequest,
  PutProposalRequest,
  ReadNotificationsRequest,
  RequestPayout201Response,
  RequestPayoutRequest,
  ResetPasswordRequest,
  ResponseErrorBadRequest,
  ResponseErrorBase,
  ResponseErrorConflict,
  ResponseErrorForbidden,
  ResponseErrorInvalidRecord,
  ResponseErrorNotFound,
  ResponseErrorUnauthorized,
  ResponseSuccess,
  SearchProposals200Response,
  SearchUsers200Response,
  SendConfirmUserEmailRequest,
  SendResetPasswordMailRequest,
  SignInUserRequest,
  SignUpUser201Response,
  SignUpUserRequest,
  UpdateUserEmailRequest,
  UpdateUserPasswordRequest,
  UpdateUserPaymentMethodRequest,
  UpdateUserProfile200Response,
  UpdateUserProfileRequest,
  VoteTopic201Response,
  VoteTopicRequest,
} from '../models/index';
import {
    CalculateProposalSupport200ResponseFromJSON,
    CalculateProposalSupport200ResponseToJSON,
    CalculateProposalSupportRequestFromJSON,
    CalculateProposalSupportRequestToJSON,
    CalculateTopic201ResponseFromJSON,
    CalculateTopic201ResponseToJSON,
    CalculateTopicRequestFromJSON,
    CalculateTopicRequestToJSON,
    CallbackSupportCheckout200ResponseFromJSON,
    CallbackSupportCheckout200ResponseToJSON,
    CallbackTopicCheckout200ResponseFromJSON,
    CallbackTopicCheckout200ResponseToJSON,
    CloseTopic200ResponseFromJSON,
    CloseTopic200ResponseToJSON,
    ConfirmUserEmailRequestFromJSON,
    ConfirmUserEmailRequestToJSON,
    ConnectUserProvider200ResponseFromJSON,
    ConnectUserProvider200ResponseToJSON,
    ConnectUserProvider400ResponseFromJSON,
    ConnectUserProvider400ResponseToJSON,
    ConnectUserProviderRequestFromJSON,
    ConnectUserProviderRequestToJSON,
    CreateBestProposals201ResponseFromJSON,
    CreateBestProposals201ResponseToJSON,
    CreateBestProposalsRequestFromJSON,
    CreateBestProposalsRequestToJSON,
    CreateDirectUpload200ResponseFromJSON,
    CreateDirectUpload200ResponseToJSON,
    CreateGuestUser201ResponseFromJSON,
    CreateGuestUser201ResponseToJSON,
    CreateStripeAccount201ResponseFromJSON,
    CreateStripeAccount201ResponseToJSON,
    CreateStripeAccountRequestFromJSON,
    CreateStripeAccountRequestToJSON,
    CreateTopicNote201ResponseFromJSON,
    CreateTopicNote201ResponseToJSON,
    CreateTopicNoteRequestFromJSON,
    CreateTopicNoteRequestToJSON,
    CreateUserPaymentMethod201ResponseFromJSON,
    CreateUserPaymentMethod201ResponseToJSON,
    CreateUserPaymentMethodRequestFromJSON,
    CreateUserPaymentMethodRequestToJSON,
    DeleteUserPaymentMethod200ResponseFromJSON,
    DeleteUserPaymentMethod200ResponseToJSON,
    GetBestProposals200ResponseFromJSON,
    GetBestProposals200ResponseToJSON,
    GetCarouselItems200ResponseFromJSON,
    GetCarouselItems200ResponseToJSON,
    GetCategories200ResponseFromJSON,
    GetCategories200ResponseToJSON,
    GetCategory200ResponseFromJSON,
    GetCategory200ResponseToJSON,
    GetEditorLink200ResponseFromJSON,
    GetEditorLink200ResponseToJSON,
    GetInquiryReplies200ResponseFromJSON,
    GetInquiryReplies200ResponseToJSON,
    GetNotifications200ResponseFromJSON,
    GetNotifications200ResponseToJSON,
    GetPayouts200ResponseFromJSON,
    GetPayouts200ResponseToJSON,
    GetProposal200ResponseFromJSON,
    GetProposal200ResponseToJSON,
    GetProposalComments200ResponseFromJSON,
    GetProposalComments200ResponseToJSON,
    GetProposalSupporters200ResponseFromJSON,
    GetProposalSupporters200ResponseToJSON,
    GetProposalSupports200ResponseFromJSON,
    GetProposalSupports200ResponseToJSON,
    GetTopic200ResponseFromJSON,
    GetTopic200ResponseToJSON,
    GetTopicDraftProposals200ResponseFromJSON,
    GetTopicDraftProposals200ResponseToJSON,
    GetTopicProposals200ResponseFromJSON,
    GetTopicProposals200ResponseToJSON,
    GetTopicVotes200ResponseFromJSON,
    GetTopicVotes200ResponseToJSON,
    GetTopics200ResponseFromJSON,
    GetTopics200ResponseToJSON,
    GetUser200ResponseFromJSON,
    GetUser200ResponseToJSON,
    GetUserBalances200ResponseFromJSON,
    GetUserBalances200ResponseToJSON,
    GetUserBankAccounts200ResponseFromJSON,
    GetUserBankAccounts200ResponseToJSON,
    GetUserComments200ResponseFromJSON,
    GetUserComments200ResponseToJSON,
    GetUserPaymentMethods200ResponseFromJSON,
    GetUserPaymentMethods200ResponseToJSON,
    GetUserProposals200ResponseFromJSON,
    GetUserProposals200ResponseToJSON,
    GetUserRevenues200ResponseFromJSON,
    GetUserRevenues200ResponseToJSON,
    GetUserSession200ResponseFromJSON,
    GetUserSession200ResponseToJSON,
    GetUserSessionOmniauthValue200ResponseFromJSON,
    GetUserSessionOmniauthValue200ResponseToJSON,
    GetUserTopic200ResponseFromJSON,
    GetUserTopic200ResponseToJSON,
    GetUserTopics200ResponseFromJSON,
    GetUserTopics200ResponseToJSON,
    PostInquiry201ResponseFromJSON,
    PostInquiry201ResponseToJSON,
    PostInquiryReply201ResponseFromJSON,
    PostInquiryReply201ResponseToJSON,
    PostInquiryReplyRequestFromJSON,
    PostInquiryReplyRequestToJSON,
    PostInquiryRequestFromJSON,
    PostInquiryRequestToJSON,
    PostProposalComment201ResponseFromJSON,
    PostProposalComment201ResponseToJSON,
    PostProposalCommentRequestFromJSON,
    PostProposalCommentRequestToJSON,
    PostProposalSupport201ResponseFromJSON,
    PostProposalSupport201ResponseToJSON,
    PostProposalSupportRequestFromJSON,
    PostProposalSupportRequestToJSON,
    PostTopic201ResponseFromJSON,
    PostTopic201ResponseToJSON,
    PostTopicProposal201ResponseFromJSON,
    PostTopicProposal201ResponseToJSON,
    PostTopicProposalRequestFromJSON,
    PostTopicProposalRequestToJSON,
    PostTopicRequestFromJSON,
    PostTopicRequestToJSON,
    PutProposalRequestFromJSON,
    PutProposalRequestToJSON,
    ReadNotificationsRequestFromJSON,
    ReadNotificationsRequestToJSON,
    RequestPayout201ResponseFromJSON,
    RequestPayout201ResponseToJSON,
    RequestPayoutRequestFromJSON,
    RequestPayoutRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    ResponseErrorBadRequestFromJSON,
    ResponseErrorBadRequestToJSON,
    ResponseErrorBaseFromJSON,
    ResponseErrorBaseToJSON,
    ResponseErrorConflictFromJSON,
    ResponseErrorConflictToJSON,
    ResponseErrorForbiddenFromJSON,
    ResponseErrorForbiddenToJSON,
    ResponseErrorInvalidRecordFromJSON,
    ResponseErrorInvalidRecordToJSON,
    ResponseErrorNotFoundFromJSON,
    ResponseErrorNotFoundToJSON,
    ResponseErrorUnauthorizedFromJSON,
    ResponseErrorUnauthorizedToJSON,
    ResponseSuccessFromJSON,
    ResponseSuccessToJSON,
    SearchProposals200ResponseFromJSON,
    SearchProposals200ResponseToJSON,
    SearchUsers200ResponseFromJSON,
    SearchUsers200ResponseToJSON,
    SendConfirmUserEmailRequestFromJSON,
    SendConfirmUserEmailRequestToJSON,
    SendResetPasswordMailRequestFromJSON,
    SendResetPasswordMailRequestToJSON,
    SignInUserRequestFromJSON,
    SignInUserRequestToJSON,
    SignUpUser201ResponseFromJSON,
    SignUpUser201ResponseToJSON,
    SignUpUserRequestFromJSON,
    SignUpUserRequestToJSON,
    UpdateUserEmailRequestFromJSON,
    UpdateUserEmailRequestToJSON,
    UpdateUserPasswordRequestFromJSON,
    UpdateUserPasswordRequestToJSON,
    UpdateUserPaymentMethodRequestFromJSON,
    UpdateUserPaymentMethodRequestToJSON,
    UpdateUserProfile200ResponseFromJSON,
    UpdateUserProfile200ResponseToJSON,
    UpdateUserProfileRequestFromJSON,
    UpdateUserProfileRequestToJSON,
    VoteTopic201ResponseFromJSON,
    VoteTopic201ResponseToJSON,
    VoteTopicRequestFromJSON,
    VoteTopicRequestToJSON,
} from '../models/index';

export interface CalculateProposalSupportOperationRequest {
    id: string;
    calculateProposalSupportRequest?: CalculateProposalSupportRequest;
}

export interface CalculateTopicOperationRequest {
    calculateTopicRequest?: CalculateTopicRequest;
}

export interface CallbackSupportCheckoutRequest {
    id: string;
}

export interface CallbackTopicCheckoutRequest {
    id: string;
}

export interface CancelTopicCheckoutRequest {
    id: string;
}

export interface ClipCommentRequest {
    id: string;
}

export interface ClipProposalRequest {
    id: string;
}

export interface ClipTopicRequest {
    id: string;
}

export interface CloseTopicRequest {
    id: string;
}

export interface ConfirmUserEmailOperationRequest {
    confirmUserEmailRequest?: ConfirmUserEmailRequest;
}

export interface ConnectUserProviderOperationRequest {
    connectUserProviderRequest?: ConnectUserProviderRequest;
}

export interface CreateBestProposalsOperationRequest {
    id: string;
    createBestProposalsRequest?: CreateBestProposalsRequest;
}

export interface CreateGuestUserRequest {
    body?: object;
}

export interface CreateStripeAccountOperationRequest {
    createStripeAccountRequest?: CreateStripeAccountRequest;
}

export interface CreateTopicNoteOperationRequest {
    id: string;
    createTopicNoteRequest?: CreateTopicNoteRequest;
}

export interface CreateUserPaymentMethodOperationRequest {
    createUserPaymentMethodRequest?: CreateUserPaymentMethodRequest;
}

export interface CreateUserPaymentMethod0Request {
    createUserPaymentMethodRequest?: CreateUserPaymentMethodRequest;
}

export interface DeleteCommentRequest {
    id: string;
}

export interface DeleteProposalRequest {
    id: string;
}

export interface DeleteTopicNoteRequest {
    id: string;
    noteId: string;
}

export interface DeleteUserPaymentMethodRequest {
    id: string;
}

export interface DisconnectUserProviderRequest {
    provider: string;
}

export interface GetBestProposalsRequest {
    id: string;
}

export interface GetCarouselItemsRequest {
    id: string;
}

export interface GetCategoriesRequest {
    parentId?: string;
}

export interface GetCategoryRequest {
    id: string;
}

export interface GetClippedCommentsRequest {
    page?: number;
}

export interface GetClippedProposalsRequest {
    page?: number;
}

export interface GetClippedTopicsRequest {
    page?: number;
}

export interface GetEditorLinkRequest {
    url: string;
}

export interface GetInquiryRepliesRequest {
    id: string;
}

export interface GetNotificationsRequest {
    page?: number;
}

export interface GetPayoutsRequest {
    page?: number;
}

export interface GetProposalRequest {
    id: string;
}

export interface GetProposalCommentsRequest {
    id: string;
    page?: string;
}

export interface GetProposalSupportersRequest {
    id: string;
    page?: string;
}

export interface GetProposalSupportsRequest {
    id: string;
    page?: string;
}

export interface GetTopicRequest {
    id: string;
}

export interface GetTopicDraftProposalsRequest {
    id: string;
}

export interface GetTopicProposalsRequest {
    id: string;
    page?: number;
    positionId?: string;
    liked?: boolean;
    order?: GetTopicProposalsOrderEnum;
}

export interface GetTopicVotesRequest {
    id: string;
    page?: number;
}

export interface GetTopicsRequest {
    page?: number;
    categoryId?: string;
    keyword?: string;
}

export interface GetUserRequest {
    id: string;
}

export interface GetUserCommentsRequest {
    userId: string;
    page?: number;
}

export interface GetUserProposalsRequest {
    userId: string;
    page?: number;
}

export interface GetUserRevenuesRequest {
    page?: number;
    date?: string;
    status?: GetUserRevenuesStatusEnum;
}

export interface GetUserTopicRequest {
    userId: string;
    id: string;
}

export interface GetUserTopicsRequest {
    userId: string;
    page?: number;
    closed?: boolean;
    waitingBestProposals?: boolean;
}

export interface LikeCommentRequest {
    id: string;
}

export interface LikeProposalRequest {
    id: string;
}

export interface LikeVoteRequest {
    id: string;
}

export interface OpenTopicRequest {
    id: string;
}

export interface PostInquiryOperationRequest {
    postInquiryRequest?: PostInquiryRequest;
}

export interface PostInquiryReplyOperationRequest {
    id: string;
    postInquiryReplyRequest?: PostInquiryReplyRequest;
}

export interface PostProposalCommentOperationRequest {
    id: string;
    postProposalCommentRequest?: PostProposalCommentRequest;
}

export interface PostProposalSupportOperationRequest {
    id: string;
    postProposalSupportRequest?: PostProposalSupportRequest;
}

export interface PostTopicOperationRequest {
    postTopicRequest?: PostTopicRequest;
}

export interface PostTopicProposalOperationRequest {
    id: string;
    postTopicProposalRequest?: PostTopicProposalRequest;
}

export interface PublishProposalRequest {
    id: string;
}

export interface PutProposalOperationRequest {
    id: string;
    putProposalRequest?: PutProposalRequest;
}

export interface ReadNotificationsOperationRequest {
    readNotificationsRequest?: ReadNotificationsRequest;
}

export interface RequestPayoutOperationRequest {
    requestPayoutRequest?: RequestPayoutRequest;
}

export interface ResetPasswordOperationRequest {
    resetPasswordRequest?: ResetPasswordRequest;
}

export interface SearchProposalsRequest {
    page?: number;
    keyword?: string;
}

export interface SearchTopicsRequest {
    page?: number;
    keyword?: string;
}

export interface SearchUsersRequest {
    page?: number;
    keyword?: string;
}

export interface SendConfirmUserEmailOperationRequest {
    sendConfirmUserEmailRequest?: SendConfirmUserEmailRequest;
}

export interface SendResetPasswordMailOperationRequest {
    sendResetPasswordMailRequest?: SendResetPasswordMailRequest;
}

export interface SignInUserOperationRequest {
    signInUserRequest?: SignInUserRequest;
}

export interface SignUpUserOperationRequest {
    signUpUserRequest?: SignUpUserRequest;
}

export interface UnclipCommentRequest {
    id: string;
}

export interface UnclipProposalRequest {
    id: string;
}

export interface UnclipTopicRequest {
    id: string;
}

export interface UnlikeCommentRequest {
    id: string;
}

export interface UnlikeProposalRequest {
    id: string;
}

export interface UnlikeVoteRequest {
    id: string;
}

export interface UpdateUserEmailOperationRequest {
    updateUserEmailRequest?: UpdateUserEmailRequest;
}

export interface UpdateUserPasswordOperationRequest {
    updateUserPasswordRequest?: UpdateUserPasswordRequest;
}

export interface UpdateUserPaymentMethodOperationRequest {
    id: string;
    updateUserPaymentMethodRequest?: UpdateUserPaymentMethodRequest;
}

export interface UpdateUserProfileOperationRequest {
    updateUserProfileRequest?: UpdateUserProfileRequest;
}

export interface VoteTopicOperationRequest {
    id: string;
    voteTopicRequest?: VoteTopicRequest;
}

/**
 * 
 */
export class WebApi extends runtime.BaseAPI {

    /**
     * 
     */
    async calculateProposalSupportRaw(requestParameters: CalculateProposalSupportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalculateProposalSupport200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling calculateProposalSupport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/supports/calculate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculateProposalSupportRequestToJSON(requestParameters.calculateProposalSupportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalculateProposalSupport200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async calculateProposalSupport(id: string, calculateProposalSupportRequest?: CalculateProposalSupportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalculateProposalSupport200Response> {
        const response = await this.calculateProposalSupportRaw({ id: id, calculateProposalSupportRequest: calculateProposalSupportRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * calculate topic
     */
    async calculateTopicRaw(requestParameters: CalculateTopicOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalculateTopic201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/calculate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculateTopicRequestToJSON(requestParameters.calculateTopicRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalculateTopic201ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * calculate topic
     */
    async calculateTopic(calculateTopicRequest?: CalculateTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalculateTopic201Response> {
        const response = await this.calculateTopicRaw({ calculateTopicRequest: calculateTopicRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 支援のチェックアウトを支払済にする
     * 
     */
    async callbackSupportCheckoutRaw(requestParameters: CallbackSupportCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallbackSupportCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling callbackSupportCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supports/{id}/checkout/callback`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackSupportCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * 支援のチェックアウトを支払済にする
     * 
     */
    async callbackSupportCheckout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallbackSupportCheckout200Response> {
        const response = await this.callbackSupportCheckoutRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async callbackTopicCheckoutRaw(requestParameters: CallbackTopicCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallbackTopicCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling callbackTopicCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/checkout/callback`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackTopicCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async callbackTopicCheckout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallbackTopicCheckout200Response> {
        const response = await this.callbackTopicCheckoutRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async cancelTopicCheckoutRaw(requestParameters: CancelTopicCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallbackTopicCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelTopicCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/checkout/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallbackTopicCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async cancelTopicCheckout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallbackTopicCheckout200Response> {
        const response = await this.cancelTopicCheckoutRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * コメントをクリップする
     * 
     */
    async clipCommentRaw(requestParameters: ClipCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clipComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{id}/clips`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * コメントをクリップする
     * 
     */
    async clipComment(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.clipCommentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 発言をクリップする
     * 
     */
    async clipProposalRaw(requestParameters: ClipProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clipProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/clips`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 発言をクリップする
     * 
     */
    async clipProposal(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.clipProposalRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * トピックをクリップする
     * 
     */
    async clipTopicRaw(requestParameters: ClipTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clipTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/clips`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * トピックをクリップする
     * 
     */
    async clipTopic(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.clipTopicRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async closeTopicRaw(requestParameters: CloseTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CloseTopic200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling closeTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/close`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CloseTopic200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async closeTopic(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloseTopic200Response> {
        const response = await this.closeTopicRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * メールアドレスを確認する
     * 
     */
    async confirmUserEmailRaw(requestParameters: ConfirmUserEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/confirmations/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmUserEmailRequestToJSON(requestParameters.confirmUserEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * メールアドレスを確認する
     * 
     */
    async confirmUserEmail(confirmUserEmailRequest?: ConfirmUserEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.confirmUserEmailRaw({ confirmUserEmailRequest: confirmUserEmailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async connectStripeAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/stripe/connect`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async connectStripeAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.connectStripeAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async connectUserProviderRaw(requestParameters: ConnectUserProviderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectUserProvider200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/providers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectUserProviderRequestToJSON(requestParameters.connectUserProviderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectUserProvider200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async connectUserProvider(connectUserProviderRequest?: ConnectUserProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectUserProvider200Response> {
        const response = await this.connectUserProviderRaw({ connectUserProviderRequest: connectUserProviderRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ベスト発言を選定する
     * post topic proposal
     */
    async createBestProposalsRaw(requestParameters: CreateBestProposalsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateBestProposals201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createBestProposals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/best_proposals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBestProposalsRequestToJSON(requestParameters.createBestProposalsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBestProposals201ResponseFromJSON(jsonValue));
    }

    /**
     * ベスト発言を選定する
     * post topic proposal
     */
    async createBestProposals(id: string, createBestProposalsRequest?: CreateBestProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateBestProposals201Response> {
        const response = await this.createBestProposalsRaw({ id: id, createBestProposalsRequest: createBestProposalsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ダイレクトアップロード情報を作成する
     * get category
     */
    async createDirectUploadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDirectUpload200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/direct_uploads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDirectUpload200ResponseFromJSON(jsonValue));
    }

    /**
     * ダイレクトアップロード情報を作成する
     * get category
     */
    async createDirectUpload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDirectUpload200Response> {
        const response = await this.createDirectUploadRaw(initOverrides);
        return await response.value();
    }

    /**
     * ゲストユーザー作成
     * create guest user
     */
    async createGuestUserRaw(requestParameters: CreateGuestUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateGuestUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/guest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateGuestUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ゲストユーザー作成
     * create guest user
     */
    async createGuestUser(body?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateGuestUser201Response> {
        const response = await this.createGuestUserRaw({ body: body }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createStripeAccountRaw(requestParameters: CreateStripeAccountOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateStripeAccount201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/stripe/connect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStripeAccountRequestToJSON(requestParameters.createStripeAccountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStripeAccount201ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createStripeAccount(createStripeAccountRequest?: CreateStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateStripeAccount201Response> {
        const response = await this.createStripeAccountRaw({ createStripeAccountRequest: createStripeAccountRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async createTopicNoteRaw(requestParameters: CreateTopicNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTopicNote201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createTopicNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/notes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTopicNoteRequestToJSON(requestParameters.createTopicNoteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTopicNote201ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async createTopicNote(id: string, createTopicNoteRequest?: CreateTopicNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTopicNote201Response> {
        const response = await this.createTopicNoteRaw({ id: id, createTopicNoteRequest: createTopicNoteRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法を登録する
     * 
     */
    async createUserPaymentMethodRaw(requestParameters: CreateUserPaymentMethodOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserPaymentMethod201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/payment_methods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserPaymentMethodRequestToJSON(requestParameters.createUserPaymentMethodRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserPaymentMethod201ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法を登録する
     * 
     */
    async createUserPaymentMethod(createUserPaymentMethodRequest?: CreateUserPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserPaymentMethod201Response> {
        const response = await this.createUserPaymentMethodRaw({ createUserPaymentMethodRequest: createUserPaymentMethodRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法を登録する
     * 
     */
    async createUserPaymentMethod_1Raw(requestParameters: CreateUserPaymentMethod0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserPaymentMethod201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/bank_accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserPaymentMethodRequestToJSON(requestParameters.createUserPaymentMethodRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserPaymentMethod201ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法を登録する
     * 
     */
    async createUserPaymentMethod_1(createUserPaymentMethodRequest?: CreateUserPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserPaymentMethod201Response> {
        const response = await this.createUserPaymentMethod_1Raw({ createUserPaymentMethodRequest: createUserPaymentMethodRequest }, initOverrides);
        return await response.value();
    }

    /**
     * コメントを削除します
     * 
     */
    async deleteCommentRaw(requestParameters: DeleteCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * コメントを削除します
     * 
     */
    async deleteComment(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.deleteCommentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 退会する
     * 
     */
    async deleteMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 退会する
     * 
     */
    async deleteMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.deleteMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * 発言の下書きを削除する
     * 
     */
    async deleteProposalRaw(requestParameters: DeleteProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 発言の下書きを削除する
     * 
     */
    async deleteProposal(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.deleteProposalRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async deleteTopicNoteRaw(requestParameters: DeleteTopicNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTopicNote.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling deleteTopicNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/notes/{note_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"note_id"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async deleteTopicNote(id: string, noteId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.deleteTopicNoteRaw({ id: id, noteId: noteId }, initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法を削除する
     * 
     */
    async deleteUserPaymentMethodRaw(requestParameters: DeleteUserPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteUserPaymentMethod200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/payment_methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteUserPaymentMethod200ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法を削除する
     * 
     */
    async deleteUserPaymentMethod(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteUserPaymentMethod200Response> {
        const response = await this.deleteUserPaymentMethodRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async disconnectUserProviderRaw(requestParameters: DisconnectUserProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling disconnectUserProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/providers/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 
     */
    async disconnectUserProvider(provider: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.disconnectUserProviderRaw({ provider: provider }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async getBestProposalsRaw(requestParameters: GetBestProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBestProposals200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBestProposals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/best_proposals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBestProposals200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async getBestProposals(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBestProposals200Response> {
        const response = await this.getBestProposalsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * カルーセルアイテムを取得する
     * get carousel items
     */
    async getCarouselItemsRaw(requestParameters: GetCarouselItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCarouselItems200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCarouselItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/carousels/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCarouselItems200ResponseFromJSON(jsonValue));
    }

    /**
     * カルーセルアイテムを取得する
     * get carousel items
     */
    async getCarouselItems(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCarouselItems200Response> {
        const response = await this.getCarouselItemsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * カテゴリー一覧を取得する
     * get categories
     */
    async getCategoriesRaw(requestParameters: GetCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCategories200Response>> {
        const queryParameters: any = {};

        if (requestParameters.parentId !== undefined) {
            queryParameters['parent_id'] = requestParameters.parentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCategories200ResponseFromJSON(jsonValue));
    }

    /**
     * カテゴリー一覧を取得する
     * get categories
     */
    async getCategories(parentId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCategories200Response> {
        const response = await this.getCategoriesRaw({ parentId: parentId }, initOverrides);
        return await response.value();
    }

    /**
     * カテゴリーを取得する
     * get category
     */
    async getCategoryRaw(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCategory200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCategory200ResponseFromJSON(jsonValue));
    }

    /**
     * カテゴリーを取得する
     * get category
     */
    async getCategory(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCategory200Response> {
        const response = await this.getCategoryRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * クリップしたコメントの一覧を取得する
     * get clipped comments
     */
    async getClippedCommentsRaw(requestParameters: GetClippedCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProposalComments200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clips/comments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProposalComments200ResponseFromJSON(jsonValue));
    }

    /**
     * クリップしたコメントの一覧を取得する
     * get clipped comments
     */
    async getClippedComments(page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProposalComments200Response> {
        const response = await this.getClippedCommentsRaw({ page: page }, initOverrides);
        return await response.value();
    }

    /**
     * クリップした発言の一覧を取得する
     * get clipped proposals
     */
    async getClippedProposalsRaw(requestParameters: GetClippedProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopicProposals200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clips/proposals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicProposals200ResponseFromJSON(jsonValue));
    }

    /**
     * クリップした発言の一覧を取得する
     * get clipped proposals
     */
    async getClippedProposals(page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopicProposals200Response> {
        const response = await this.getClippedProposalsRaw({ page: page }, initOverrides);
        return await response.value();
    }

    /**
     * クリップしたトピックの一覧を取得する
     * get clipped topics
     */
    async getClippedTopicsRaw(requestParameters: GetClippedTopicsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopics200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clips/topics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopics200ResponseFromJSON(jsonValue));
    }

    /**
     * クリップしたトピックの一覧を取得する
     * get clipped topics
     */
    async getClippedTopics(page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopics200Response> {
        const response = await this.getClippedTopicsRaw({ page: page }, initOverrides);
        return await response.value();
    }

    /**
     * get url data
     */
    async getEditorLinkRaw(requestParameters: GetEditorLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEditorLink200Response>> {
        if (requestParameters.url === null || requestParameters.url === undefined) {
            throw new runtime.RequiredError('url','Required parameter requestParameters.url was null or undefined when calling getEditorLink.');
        }

        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/editors/link`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEditorLink200ResponseFromJSON(jsonValue));
    }

    /**
     * get url data
     */
    async getEditorLink(url: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEditorLink200Response> {
        const response = await this.getEditorLinkRaw({ url: url }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async getInquiryRepliesRaw(requestParameters: GetInquiryRepliesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInquiryReplies200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInquiryReplies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/inquiries/{id}/replies`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInquiryReplies200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async getInquiryReplies(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInquiryReplies200Response> {
        const response = await this.getInquiryRepliesRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * get notification
     */
    async getNotificationsRaw(requestParameters: GetNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNotifications200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNotifications200ResponseFromJSON(jsonValue));
    }

    /**
     * get notification
     */
    async getNotifications(page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNotifications200Response> {
        const response = await this.getNotificationsRaw({ page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getPayoutsRaw(requestParameters: GetPayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPayouts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPayouts200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getPayouts(page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPayouts200Response> {
        const response = await this.getPayoutsRaw({ page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 発言を取得する
     * get proposal
     */
    async getProposalRaw(requestParameters: GetProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProposal200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProposal200ResponseFromJSON(jsonValue));
    }

    /**
     * 発言を取得する
     * get proposal
     */
    async getProposal(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProposal200Response> {
        const response = await this.getProposalRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 発言に対するコメントを取得する
     * get proposal comments
     */
    async getProposalCommentsRaw(requestParameters: GetProposalCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProposalComments200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProposalComments.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProposalComments200ResponseFromJSON(jsonValue));
    }

    /**
     * 発言に対するコメントを取得する
     * get proposal comments
     */
    async getProposalComments(id: string, page?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProposalComments200Response> {
        const response = await this.getProposalCommentsRaw({ id: id, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 発言に対する支援者を取得する
     * 
     */
    async getProposalSupportersRaw(requestParameters: GetProposalSupportersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProposalSupporters200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProposalSupporters.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/supporters`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProposalSupporters200ResponseFromJSON(jsonValue));
    }

    /**
     * 発言に対する支援者を取得する
     * 
     */
    async getProposalSupporters(id: string, page?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProposalSupporters200Response> {
        const response = await this.getProposalSupportersRaw({ id: id, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 発言に対する支援を取得する
     * 
     */
    async getProposalSupportsRaw(requestParameters: GetProposalSupportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProposalSupports200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProposalSupports.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/supports`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProposalSupports200ResponseFromJSON(jsonValue));
    }

    /**
     * 発言に対する支援を取得する
     * 
     */
    async getProposalSupports(id: string, page?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProposalSupports200Response> {
        const response = await this.getProposalSupportsRaw({ id: id, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * トピックを取得する
     * get topic
     */
    async getTopicRaw(requestParameters: GetTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopic200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopic200ResponseFromJSON(jsonValue));
    }

    /**
     * トピックを取得する
     * get topic
     */
    async getTopic(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopic200Response> {
        const response = await this.getTopicRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * トピックに対する発言の下書きを取得する
     * get topic draft proposal
     */
    async getTopicDraftProposalsRaw(requestParameters: GetTopicDraftProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopicDraftProposals200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTopicDraftProposals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/proposals/draft`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicDraftProposals200ResponseFromJSON(jsonValue));
    }

    /**
     * トピックに対する発言の下書きを取得する
     * get topic draft proposal
     */
    async getTopicDraftProposals(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopicDraftProposals200Response> {
        const response = await this.getTopicDraftProposalsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 話題に対する発言の一覧を取得する
     * get topic proposals
     */
    async getTopicProposalsRaw(requestParameters: GetTopicProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopicProposals200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTopicProposals.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.positionId !== undefined) {
            queryParameters['position_id'] = requestParameters.positionId;
        }

        if (requestParameters.liked !== undefined) {
            queryParameters['liked'] = requestParameters.liked;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/proposals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicProposals200ResponseFromJSON(jsonValue));
    }

    /**
     * 話題に対する発言の一覧を取得する
     * get topic proposals
     */
    async getTopicProposals(id: string, page?: number, positionId?: string, liked?: boolean, order?: GetTopicProposalsOrderEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopicProposals200Response> {
        const response = await this.getTopicProposalsRaw({ id: id, page: page, positionId: positionId, liked: liked, order: order }, initOverrides);
        return await response.value();
    }

    /**
     * コメント付きの投票一覧を取得する
     * get topic votes
     */
    async getTopicVotesRaw(requestParameters: GetTopicVotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopicVotes200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTopicVotes.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/votes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopicVotes200ResponseFromJSON(jsonValue));
    }

    /**
     * コメント付きの投票一覧を取得する
     * get topic votes
     */
    async getTopicVotes(id: string, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopicVotes200Response> {
        const response = await this.getTopicVotesRaw({ id: id, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * トピック一覧を取得する
     * get topics
     */
    async getTopicsRaw(requestParameters: GetTopicsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopics200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['category_id'] = requestParameters.categoryId;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopics200ResponseFromJSON(jsonValue));
    }

    /**
     * トピック一覧を取得する
     * get topics
     */
    async getTopics(page?: number, categoryId?: string, keyword?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopics200Response> {
        const response = await this.getTopicsRaw({ page: page, categoryId: categoryId, keyword: keyword }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザー情報を取得する
     * 
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUser200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUser200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー情報を取得する
     * 
     */
    async getUser(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUser200Response> {
        const response = await this.getUserRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 残高を取得する
     * 
     */
    async getUserBalancesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserBalances200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/balances`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserBalances200ResponseFromJSON(jsonValue));
    }

    /**
     * 残高を取得する
     * 
     */
    async getUserBalances(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserBalances200Response> {
        const response = await this.getUserBalancesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 銀行口座一覧を取得する
     * 
     */
    async getUserBankAccountsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserBankAccounts200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/bank_accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserBankAccounts200ResponseFromJSON(jsonValue));
    }

    /**
     * 銀行口座一覧を取得する
     * 
     */
    async getUserBankAccounts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserBankAccounts200Response> {
        const response = await this.getUserBankAccountsRaw(initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのコメントを取得する
     * 
     */
    async getUserCommentsRaw(requestParameters: GetUserCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserComments200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserComments.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/comments`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserComments200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのコメントを取得する
     * 
     */
    async getUserComments(userId: string, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserComments200Response> {
        const response = await this.getUserCommentsRaw({ userId: userId, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法の一覧を取得する
     * 
     */
    async getUserPaymentMethodsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserPaymentMethods200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/payment_methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserPaymentMethods200ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法の一覧を取得する
     * 
     */
    async getUserPaymentMethods(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserPaymentMethods200Response> {
        const response = await this.getUserPaymentMethodsRaw(initOverrides);
        return await response.value();
    }

    /**
     * ユーザーの発言を取得する
     * 
     */
    async getUserProposalsRaw(requestParameters: GetUserProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserProposals200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserProposals.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/proposals`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserProposals200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーの発言を取得する
     * 
     */
    async getUserProposals(userId: string, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserProposals200Response> {
        const response = await this.getUserProposalsRaw({ userId: userId, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 収益一覧を取得する
     * 
     */
    async getUserRevenuesRaw(requestParameters: GetUserRevenuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserRevenues200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/revenues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserRevenues200ResponseFromJSON(jsonValue));
    }

    /**
     * 収益一覧を取得する
     * 
     */
    async getUserRevenues(page?: number, date?: string, status?: GetUserRevenuesStatusEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserRevenues200Response> {
        const response = await this.getUserRevenuesRaw({ page: page, date: date, status: status }, initOverrides);
        return await response.value();
    }

    /**
     * 認証状態を取得する
     * 
     */
    async getUserSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserSession200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserSession200ResponseFromJSON(jsonValue));
    }

    /**
     * 認証状態を取得する
     * 
     */
    async getUserSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserSession200Response> {
        const response = await this.getUserSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * omniauthのセッション情報を取得する
     * 
     */
    async getUserSessionOmniauthValueRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserSessionOmniauthValue200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/sessions/omniauth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserSessionOmniauthValue200ResponseFromJSON(jsonValue));
    }

    /**
     * omniauthのセッション情報を取得する
     * 
     */
    async getUserSessionOmniauthValue(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserSessionOmniauthValue200Response> {
        const response = await this.getUserSessionOmniauthValueRaw(initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのトピックを取得する
     * 
     */
    async getUserTopicRaw(requestParameters: GetUserTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserTopic200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserTopic.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/topics/{id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserTopic200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのトピックを取得する
     * 
     */
    async getUserTopic(userId: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserTopic200Response> {
        const response = await this.getUserTopicRaw({ userId: userId, id: id }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのトピックを取得する
     * 
     */
    async getUserTopicsRaw(requestParameters: GetUserTopicsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserTopics200Response>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserTopics.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.closed !== undefined) {
            queryParameters['closed'] = requestParameters.closed;
        }

        if (requestParameters.waitingBestProposals !== undefined) {
            queryParameters['waiting_best_proposals'] = requestParameters.waitingBestProposals;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/topics`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserTopics200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのトピックを取得する
     * 
     */
    async getUserTopics(userId: string, page?: number, closed?: boolean, waitingBestProposals?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserTopics200Response> {
        const response = await this.getUserTopicsRaw({ userId: userId, page: page, closed: closed, waitingBestProposals: waitingBestProposals }, initOverrides);
        return await response.value();
    }

    /**
     * コメントに対していいねを投稿する
     * 
     */
    async likeCommentRaw(requestParameters: LikeCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling likeComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{id}/likes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * コメントに対していいねを投稿する
     * 
     */
    async likeComment(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.likeCommentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 発言に対していいねを投稿する
     * 
     */
    async likeProposalRaw(requestParameters: LikeProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling likeProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/likes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 発言に対していいねを投稿する
     * 
     */
    async likeProposal(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.likeProposalRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 投票に対していいねを投稿する
     * 
     */
    async likeVoteRaw(requestParameters: LikeVoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling likeVote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/votes/{id}/likes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 投票に対していいねを投稿する
     * 
     */
    async likeVote(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.likeVoteRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async openTopicRaw(requestParameters: OpenTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CloseTopic200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling openTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/open`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CloseTopic200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async openTopic(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloseTopic200Response> {
        const response = await this.openTopicRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * post inquiry
     */
    async postInquiryRaw(requestParameters: PostInquiryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostInquiry201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inquiries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostInquiryRequestToJSON(requestParameters.postInquiryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostInquiry201ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * post inquiry
     */
    async postInquiry(postInquiryRequest?: PostInquiryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostInquiry201Response> {
        const response = await this.postInquiryRaw({ postInquiryRequest: postInquiryRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postInquiryReplyRaw(requestParameters: PostInquiryReplyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostInquiryReply201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postInquiryReply.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inquiries/{id}/replies`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostInquiryReplyRequestToJSON(requestParameters.postInquiryReplyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostInquiryReply201ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postInquiryReply(id: string, postInquiryReplyRequest?: PostInquiryReplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostInquiryReply201Response> {
        const response = await this.postInquiryReplyRaw({ id: id, postInquiryReplyRequest: postInquiryReplyRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 発言に対してコメントを投稿する
     * 
     */
    async postProposalCommentRaw(requestParameters: PostProposalCommentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostProposalComment201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postProposalComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostProposalCommentRequestToJSON(requestParameters.postProposalCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostProposalComment201ResponseFromJSON(jsonValue));
    }

    /**
     * 発言に対してコメントを投稿する
     * 
     */
    async postProposalComment(id: string, postProposalCommentRequest?: PostProposalCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostProposalComment201Response> {
        const response = await this.postProposalCommentRaw({ id: id, postProposalCommentRequest: postProposalCommentRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 発言に対して支援する
     * 
     */
    async postProposalSupportRaw(requestParameters: PostProposalSupportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostProposalSupport201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postProposalSupport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/supports`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostProposalSupportRequestToJSON(requestParameters.postProposalSupportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostProposalSupport201ResponseFromJSON(jsonValue));
    }

    /**
     * 発言に対して支援する
     * 
     */
    async postProposalSupport(id: string, postProposalSupportRequest?: PostProposalSupportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostProposalSupport201Response> {
        const response = await this.postProposalSupportRaw({ id: id, postProposalSupportRequest: postProposalSupportRequest }, initOverrides);
        return await response.value();
    }

    /**
     * トピックを投稿する
     * create topic
     */
    async postTopicRaw(requestParameters: PostTopicOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostTopic201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostTopicRequestToJSON(requestParameters.postTopicRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostTopic201ResponseFromJSON(jsonValue));
    }

    /**
     * トピックを投稿する
     * create topic
     */
    async postTopic(postTopicRequest?: PostTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostTopic201Response> {
        const response = await this.postTopicRaw({ postTopicRequest: postTopicRequest }, initOverrides);
        return await response.value();
    }

    /**
     * トピックに対して発言を投稿する
     * post topic proposal
     */
    async postTopicProposalRaw(requestParameters: PostTopicProposalOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostTopicProposal201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postTopicProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/proposals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostTopicProposalRequestToJSON(requestParameters.postTopicProposalRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostTopicProposal201ResponseFromJSON(jsonValue));
    }

    /**
     * トピックに対して発言を投稿する
     * post topic proposal
     */
    async postTopicProposal(id: string, postTopicProposalRequest?: PostTopicProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostTopicProposal201Response> {
        const response = await this.postTopicProposalRaw({ id: id, postTopicProposalRequest: postTopicProposalRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 発言を公開する
     * 
     */
    async publishProposalRaw(requestParameters: PublishProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostTopicProposal201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publishProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostTopicProposal201ResponseFromJSON(jsonValue));
    }

    /**
     * 発言を公開する
     * 
     */
    async publishProposal(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostTopicProposal201Response> {
        const response = await this.publishProposalRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 発言を更新する
     * 
     */
    async putProposalRaw(requestParameters: PutProposalOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostTopicProposal201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutProposalRequestToJSON(requestParameters.putProposalRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostTopicProposal201ResponseFromJSON(jsonValue));
    }

    /**
     * 発言を更新する
     * 
     */
    async putProposal(id: string, putProposalRequest?: PutProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostTopicProposal201Response> {
        const response = await this.putProposalRaw({ id: id, putProposalRequest: putProposalRequest }, initOverrides);
        return await response.value();
    }

    /**
     * read notifications
     */
    async readNotificationsRaw(requestParameters: ReadNotificationsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReadNotificationsRequestToJSON(requestParameters.readNotificationsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * read notifications
     */
    async readNotifications(readNotificationsRequest?: ReadNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.readNotificationsRaw({ readNotificationsRequest: readNotificationsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 出金申請をする
     * request payout
     */
    async requestPayoutRaw(requestParameters: RequestPayoutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RequestPayout201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestPayoutRequestToJSON(requestParameters.requestPayoutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestPayout201ResponseFromJSON(jsonValue));
    }

    /**
     * 出金申請をする
     * request payout
     */
    async requestPayout(requestPayoutRequest?: RequestPayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RequestPayout201Response> {
        const response = await this.requestPayoutRaw({ requestPayoutRequest: requestPayoutRequest }, initOverrides);
        return await response.value();
    }

    /**
     * パスワードをリセットする
     * 
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * パスワードをリセットする
     * 
     */
    async resetPassword(resetPasswordRequest?: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.resetPasswordRaw({ resetPasswordRequest: resetPasswordRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 発言を検索する
     * get proposal
     */
    async searchProposalsRaw(requestParameters: SearchProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchProposals200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchProposals200ResponseFromJSON(jsonValue));
    }

    /**
     * 発言を検索する
     * get proposal
     */
    async searchProposals(page?: number, keyword?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchProposals200Response> {
        const response = await this.searchProposalsRaw({ page: page, keyword: keyword }, initOverrides);
        return await response.value();
    }

    /**
     * トピックを検索する
     * search topics
     */
    async searchTopicsRaw(requestParameters: SearchTopicsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopics200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopics200ResponseFromJSON(jsonValue));
    }

    /**
     * トピックを検索する
     * search topics
     */
    async searchTopics(page?: number, keyword?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopics200Response> {
        const response = await this.searchTopicsRaw({ page: page, keyword: keyword }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async searchUsersRaw(requestParameters: SearchUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchUsers200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchUsers200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async searchUsers(page?: number, keyword?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchUsers200Response> {
        const response = await this.searchUsersRaw({ page: page, keyword: keyword }, initOverrides);
        return await response.value();
    }

    /**
     * メールアドレス確認用のメールを送信する
     * 
     */
    async sendConfirmUserEmailRaw(requestParameters: SendConfirmUserEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/confirmations/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendConfirmUserEmailRequestToJSON(requestParameters.sendConfirmUserEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * メールアドレス確認用のメールを送信する
     * 
     */
    async sendConfirmUserEmail(sendConfirmUserEmailRequest?: SendConfirmUserEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.sendConfirmUserEmailRaw({ sendConfirmUserEmailRequest: sendConfirmUserEmailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * パスワードリセット用のメールを送信する
     * 
     */
    async sendResetPasswordMailRaw(requestParameters: SendResetPasswordMailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendResetPasswordMailRequestToJSON(requestParameters.sendResetPasswordMailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * パスワードリセット用のメールを送信する
     * 
     */
    async sendResetPasswordMail(sendResetPasswordMailRequest?: SendResetPasswordMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.sendResetPasswordMailRaw({ sendResetPasswordMailRequest: sendResetPasswordMailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーログイン
     * sign in user
     */
    async signInUserRaw(requestParameters: SignInUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInUserRequestToJSON(requestParameters.signInUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUpUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーログイン
     * sign in user
     */
    async signInUser(signInUserRequest?: SignInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpUser201Response> {
        const response = await this.signInUserRaw({ signInUserRequest: signInUserRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ログアウトさせる
     * 
     */
    async signOutUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/sessions`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * ログアウトさせる
     * 
     */
    async signOutUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.signOutUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * ユーザー登録
     * sign up user
     */
    async signUpUserRaw(requestParameters: SignUpUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpUserRequestToJSON(requestParameters.signUpUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUpUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー登録
     * sign up user
     */
    async signUpUser(signUpUserRequest?: SignUpUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpUser201Response> {
        const response = await this.signUpUserRaw({ signUpUserRequest: signUpUserRequest }, initOverrides);
        return await response.value();
    }

    /**
     * コメントのクリップを解除する
     * 
     */
    async unclipCommentRaw(requestParameters: UnclipCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unclipComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{id}/clips`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * コメントのクリップを解除する
     * 
     */
    async unclipComment(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.unclipCommentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 発言のクリップを解除する
     * 
     */
    async unclipProposalRaw(requestParameters: UnclipProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unclipProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/clips`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 発言のクリップを解除する
     * 
     */
    async unclipProposal(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.unclipProposalRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * トピックのクリップを解除する
     * 
     */
    async unclipTopicRaw(requestParameters: UnclipTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unclipTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/clips`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * トピックのクリップを解除する
     * 
     */
    async unclipTopic(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.unclipTopicRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * コメントに対するいいねを取り消す
     * 
     */
    async unlikeCommentRaw(requestParameters: UnlikeCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unlikeComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{id}/likes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * コメントに対するいいねを取り消す
     * 
     */
    async unlikeComment(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.unlikeCommentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 発言に対するいいねを取り消す
     * 
     */
    async unlikeProposalRaw(requestParameters: UnlikeProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unlikeProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/likes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 発言に対するいいねを取り消す
     * 
     */
    async unlikeProposal(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.unlikeProposalRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 投票に対するいいねを取り消す
     * 
     */
    async unlikeVoteRaw(requestParameters: UnlikeVoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unlikeVote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/votes/{id}/likes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 投票に対するいいねを取り消す
     * 
     */
    async unlikeVote(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.unlikeVoteRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * メールアドレスを更新する
     * 
     */
    async updateUserEmailRaw(requestParameters: UpdateUserEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserEmailRequestToJSON(requestParameters.updateUserEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * メールアドレスを更新する
     * 
     */
    async updateUserEmail(updateUserEmailRequest?: UpdateUserEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.updateUserEmailRaw({ updateUserEmailRequest: updateUserEmailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * パスワードを更新する
     * 
     */
    async updateUserPasswordRaw(requestParameters: UpdateUserPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPasswordRequestToJSON(requestParameters.updateUserPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * パスワードを更新する
     * 
     */
    async updateUserPassword(updateUserPasswordRequest?: UpdateUserPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.updateUserPasswordRaw({ updateUserPasswordRequest: updateUserPasswordRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法を更新する
     * 
     */
    async updateUserPaymentMethodRaw(requestParameters: UpdateUserPaymentMethodOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserPaymentMethod201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/payment_methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPaymentMethodRequestToJSON(requestParameters.updateUserPaymentMethodRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserPaymentMethod201ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法を更新する
     * 
     */
    async updateUserPaymentMethod(id: string, updateUserPaymentMethodRequest?: UpdateUserPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserPaymentMethod201Response> {
        const response = await this.updateUserPaymentMethodRaw({ id: id, updateUserPaymentMethodRequest: updateUserPaymentMethodRequest }, initOverrides);
        return await response.value();
    }

    /**
     * プロフィールを更新する
     * 
     */
    async updateUserProfileRaw(requestParameters: UpdateUserProfileOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUserProfile200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/profile`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserProfileRequestToJSON(requestParameters.updateUserProfileRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserProfile200ResponseFromJSON(jsonValue));
    }

    /**
     * プロフィールを更新する
     * 
     */
    async updateUserProfile(updateUserProfileRequest?: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUserProfile200Response> {
        const response = await this.updateUserProfileRaw({ updateUserProfileRequest: updateUserProfileRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 投票する
     * 
     */
    async voteTopicRaw(requestParameters: VoteTopicOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VoteTopic201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling voteTopic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/topics/{id}/votes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VoteTopicRequestToJSON(requestParameters.voteTopicRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VoteTopic201ResponseFromJSON(jsonValue));
    }

    /**
     * 投票する
     * 
     */
    async voteTopic(id: string, voteTopicRequest?: VoteTopicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VoteTopic201Response> {
        const response = await this.voteTopicRaw({ id: id, voteTopicRequest: voteTopicRequest }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetTopicProposalsOrderEnum = {
    LikesCountdesc: 'likes_count:desc'
} as const;
export type GetTopicProposalsOrderEnum = typeof GetTopicProposalsOrderEnum[keyof typeof GetTopicProposalsOrderEnum];
/**
 * @export
 */
export const GetUserRevenuesStatusEnum = {
    Received: 'received',
    Accepted: 'accepted',
    Expired: 'expired'
} as const;
export type GetUserRevenuesStatusEnum = typeof GetUserRevenuesStatusEnum[keyof typeof GetUserRevenuesStatusEnum];
