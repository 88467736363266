import { WebApi, Configuration as WebConfiguration, ConfigurationParameters } from "./web";

type Conf<T> = new(parameters: ConfigurationParameters) => T;
export type API<T, CT, C extends Conf<CT>> = new(conf: C) => T;

type Option = {
  basePath?: string;
  credentials?: boolean;
  accessToken?: ConfigurationParameters["accessToken"];
};

const API_ORIGIN = process.env.API_ORIGIN || process.env.NEXT_PUBLIC_API_ORIGIN;

function createConfiguration<T>(conf: Conf<T>, options: Option = {}): T {
  const { basePath = "/api", credentials, accessToken = "" } = options;

  return new conf({
    basePath: API_ORIGIN + basePath,
    credentials: credentials ? "include" : undefined,
    accessToken,
  });
}

// TODO: 実質的にはhookとして振る舞ってないのでなんとかする
export function useWebAPI(options?: Option) {
  return createWebAPI(options);
}

export function createWebAPI(options?: Option) {
  return new WebApi(createConfiguration(WebConfiguration, { basePath: "/api/web", ...options }));
}
